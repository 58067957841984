import JSysenvTabGeneral from './JSysenvTabGeneral'
import JSysenvTabDatabase from './JSysenvTabDatabase'
import JSysenvTabLayout from './JSysenvTabLayout'
import JSysenvTabContainer from './JSysenvTabContainer'

export default {
  JSysenvTabGeneral,
  JSysenvTabDatabase,
  JSysenvTabLayout,
  JSysenvTabContainer,
}
