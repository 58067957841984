<template>
  <v-flex v-model="tabActivator" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Layout</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        General
        <a class="close" v-on:click="collapsed['general'] = !collapsed['general']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['general']">
        <div class="type_common_input">
          <label>Spacing</label>
          <input v-model="spacing" type="text"/>
        </div>
        <div class="type_common_input">
          <label>Padding</label>
          <input v-model="padding" type="text" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="background" title="Background"></j-color-picker>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations } from "vuex"
import StaticOptions from '@/mixins/staticOptions'


export default {
  name: 'j-chart-tab-layout',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      general: true,
    },
    localLayoutAttrs: {},
    tabActivator: null,
  }),
  watch: {
    'dashboardItem.layoutAttrs': {
      handler(val) {
        if (!val) return
        this.localLayoutAttrs = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.DASHBOARD_ADMIN, ['dashboardItem']),

    spacing: {
      get() { return this.localLayoutAttrs.spacing },
      set(val) { this.setDashboardItem({ layoutAttrs: { ...this.localLayoutAttrs, spacing: val }}) }
    },
    padding: {
      get() { return this.localLayoutAttrs.padding },
      set(val) { this.setDashboardItem({ layoutAttrs: { ...this.localLayoutAttrs, padding: val }}) }
    },
    background: {
      get() { return this.localLayoutAttrs.background },
      set(val) { this.setDashboardItem({ layoutAttrs: { ...this.localLayoutAttrs, background: val }}) }
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.DASHBOARD_ADMIN, ['setDashboardItem']),

  }
}
</script>
