import { mapState, mapMutations } from "vuex"
import __C from '@/primitives/_constant_'
import JSysenvTabGeneral from '@/templates/JSysenvTabGeneral'

export default {
  extends: JSysenvTabGeneral,
  name: 'j-sysenv-tab-general',
  computed: {
    ...mapState(__C.STORE_NAMESPACE.DASHBOARD_ADMIN, ['dashboardItem']),

    catCode() { return this.dashboardItem.catCode }
  },
  watch: {
    catCode() {
      this.getImportOptions()
    },
    dashboardItem: {
      handler(val) {
        if(!val) return
        this.dataItem = val
      },
      deep: true
    }
  },
  created() {
    this.dataItemFunc = this.setDashboardItem
    this.importOptionFunc = this.getImportOptions
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.DASHBOARD_ADMIN, ['setDashboardItem']),

    getImportOptions() {
      this.systemCodeService.dashboardOptions(this.dashboardItem.catCode, (res) => {
        this.importOptions = res
      })
    },
  }
}
