<template>
  <v-flex v-model="bar3d_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Database</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Configuration
      </div>
      <v-flex class="control">
        <div class="column align_end">
          <label class="alignself_start">Filtering Options (JSON)</label>
          <div justify="center">
            <v-btn @click.stop="exp.J=true" class="type_openTextarea">{{ getQueryParts('jsonProps') }}</v-btn>
            <j-modal-scripter 
              v-model="jsonProps" 
              sub-title=""
              title="JSON Properties"
              :message="jsonMessage"
              :opened="exp.J"
              :valid="jsonValidated"
              @close="exp.J=false;"
              @save="exp.J=false;onSave();"
              @validate="onValidate('jsonProps')"
            />
          </div>
        </div>
        <div class="type_common_input" style="margin-top: 2.5rem;">
          <span class="label">Use Menu Component</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="useMenuComponent"
                type="radio"
                name="LegendDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div v-if="useMenuComponent=='Y'" class="type_common_select">
          <label>Menu Component</label>
          <select v-model="menuComponentIdx" style="width: 10rem;">
            <option v-for="option in menuComponentOptions" :key="option.index" :value="option.id">{{option.name}}</option>
          </select>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapGetters, mapMutations } from "vuex"
import { ChartLibraryService, SystemCodeService, SystemValuesService } from "@/services"
import { QueryValidationMixin } from "@/mixins/sqlValidator"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-sysenv-tab-database',
  mixins: [
    QueryValidationMixin,
    StaticOptions
  ],
  props: {
    type: String
  },
  data: () => ({
    // Service Instances -------------
    chartLibraryService: null,
    systemCodeService: null,
    systemValuesService: null,

    // rules -------------------------
    codeRules: [v => (!!v && v != 'ALL') || "Required"],
    defaultRules: [v => !!v || "Required"],

    // general -----------------------
    exp: {
      J: false,
      Q: false,
      S: false,
      T: false,
    },
    validated: {
      jsonProps: { valid: true, message: '' },
      qSummary: { valid: true, message: '' },
      qDataset: { valid: true, message: '' },
      qSp: { valid: true, message: '' },
    },

    chartReference: '',
    chartReferenceOptions: [],
    databaseOptions: [],
    tableOptions: [],
    jsonPropsPrev: [],

    menuComponentOptions: [],

    bar3d_general: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.DASHBOARD_ADMIN, ['dashboardItem']),
    ...mapGetters(__C.STORE_NAMESPACE.DASHBOARD_ADMIN, ['database']),

    jsonValidated() { return this.validated.jsonProps.valid },
    jsonMessage() { return this.validated.jsonProps.message },
    sqlSummaryValidated() { return this.validated.qSummary.valid },
    sqlSummaryMessage() { return this.validated.qSummary.message },
    sqlDatasetValidated() { return this.validated.qDataset.valid },
    sqlDatasetMessage() { return this.validated.qDataset.message },
    spValidated() { return this.validated.qSp.valid },
    spMessage() { return this.validated.qSp.message },

    isDirectAccess() { return this.directAccess == 'Y' ? true : false },
    isTabTypeDatatable() { return this.type == 'datatable' ? true : false },
    isTabTypeDashboard() { return this.type == 'dashboard' ? true : false },

    directAccess: {
      get() { return this.database.directAccess },
      set(val) { this.setDashboardItem({ directAccess: val })}
    },
    dbName: {
      get() { return this.database.dbName },
      set(val) { this.setDashboardItem({ dbName: val })}
    },
    schemaName: {
      get() { return this.database.schemaName },
      set(val) { this.setDashboardItem({ schemaName: val })}
    },
    tableName: {
      get() { return this.database.tableName },
      set(val) { this.setDashboardItem({ tableName: val })}
    },
    qApplied: {
      get() { return this.database.qApplied },
      set(val) { this.setDashboardItem({ qApplied: val })}
    },
    qDataset: {
      get() { return this.database.qDataset },
      set(val) { this.setDashboardItem({ qDataset: val })}
    },
    qSp: {
      get() { return this.database.qSp },
      set(val) { this.setDashboardItem({ qSp: val })}
    },
    version: {
      get() { return this.database.version },
      set(val) { this.setDashboardItem({ version: val })}
    },
    jsonProps: {
      get() { return this.database.jsonProps },
      set(val) { this.setDashboardItem({ jsonProps: val })}
    },

    useMenuComponent: {
      get() { return this.dashboardItem.moreAttrs && this.dashboardItem.moreAttrs.useMenuComponent ? this.dashboardItem.moreAttrs.useMenuComponent : 'N' },
      set(val) { 
        if(!this.dashboardItem.moreAttrs) this.setDashboardItem({ moreAttrs: {} })

        let moreAttrs_ = JSON.parse(JSON.stringify(this.dashboardItem.moreAttrs))
        moreAttrs_.useMenuComponent = val
        this.setDashboardItem({ moreAttrs: moreAttrs_ })
      }
    },
    menuComponentIdx: {
      get() { return this.dashboardItem.moreAttrs && this.dashboardItem.moreAttrs.menuComponentIdx ? this.dashboardItem.moreAttrs.menuComponentIdx : 0 },
      set(val) { 
        if(!this.dashboardItem.moreAttrs) this.setDashboardItem({ moreAttrs: {} })
        
        let moreAttrs_ = JSON.parse(JSON.stringify(this.dashboardItem.moreAttrs))
        moreAttrs_.menuComponentIdx = val
        this.setDashboardItem({ moreAttrs: moreAttrs_ })
      }
    },
  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
    this.systemCodeService = new SystemCodeService()
    this.systemValuesService = new SystemValuesService()
  },
  mounted() {
    this.systemValuesService.sysValuesDatabases(res => {
      if (!res) this.databaseOptions = []
      else this.databaseOptions = res
    })
    this.systemCodeService.chartOptions(__C.CHART.CAT_CODE_COMPLIB_SVG_NAVIGATION, 'ALL', res => {
      this.menuComponentOptions = res
    })
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.DASHBOARD_ADMIN, ['setDashboardItem']),

    databaseChanged(db) {
      this.systemValuesService.sysValuesTables(db, res => {
        if (!res) this.tableOptions = []
        else this.tableOptions = res
      })
    },
    generateJson(dataSource) {
      let headers = []

      dataSource.forEach((c, i) => {
        if(['idx'].includes(c.columnName.toLowerCase())) return
        
        if(this.isTabTypeDatatable) headers.push({ 
          type: 'text', 
          text: c.columnDesc, 
          value: c.columnName, 
          width: 85, 
          visible: true,
          tSql: '',

        }); else headers.push({ 
          type: 'text', 
          text: c.columnDesc,
          value: c.columnName,
          filterable: false,
          filterText: c.columnDesc,
          filterOption: 'mselect',
          filterOrder: 0,
          filterChain: [],
          filterChainOrder: 0
        })
      })
      if(this.isTabTypeDatatable) headers.push({
        type: 'spacer',
        text: '',
        visible: true,
      })
      
      this.jsonProps = JSON.stringify(headers, null, 4)
    },
    tableChanged(table) {
      let tableOption_ = this.tableOptions.find(option => option.tableName == table)
      if(!tableOption_) return

      this.schemaName = tableOption_.schemaName
      this.systemValuesService.sysValuesColumns(this.dbName, table, res => { this.generateJson(res) })
    },
    onSave() {
      this.$emit('save')
    },
    onValidate(typeName) {
      if(typeName == 'jsonProps') {
        this.validated.jsonProps = this.validateJson(this.jsonProps)
        if(this.validated.jsonProps.valid) this.jsonProps = this.validated.jsonProps.json
      } else {
        this.validated[typeName] = this.validateSql(this[typeName])
      }
    },
    setData(mode) {
      if(mode == __C.FORM.EDIT_MODE_NEW) {
        // 
      } else {
        // if this.dbName is null/empty/undefinded, cors error will be occurred.
        if(this.dbName) this.databaseChanged(this.dbName)
      }
    }
  }
}
</script>
